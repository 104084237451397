import { useState } from 'react';
import './MenuProfileComponent.scss';
import {
	DEFAULT_USER_MENU,
	USER_ADMIN_MENU,
	IMenuProps,
	IMenuItem,
	DEFAULT_FULL_USER_MENU
} from './model';
import { PROFILE_ROUTES } from '../../../common/constants';
import { UserType } from '../../../common/model';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';

export const MenuProfileComponent: React.FC<IMenuProps> = ({ onSelectedItem, selectedItem }) => {
	const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const toggleSubMenu = () => {
		setOpenSubMenu(!openSubMenu);
	};
	const handleItemMenu = (index: any) => {
		onSelectedItem(index);
		if (
			index !== PROFILE_ROUTES.BRAND_INFO ||
			index !== PROFILE_ROUTES.PRODUCT_INFO ||
			index !== PROFILE_ROUTES.ONBOARD_FAQ
		) {
			setOpenSubMenu(false);
		}
	};
	const userFullAccess = findUserAccessType(userState?.user_type) !== UserType.Brand;
	const menuItems: IMenuItem =
		process.env.REACT_APP_USE_DEFAULT_MENU === 'true'
			? userFullAccess
				? DEFAULT_USER_MENU
				: DEFAULT_FULL_USER_MENU
			: USER_ADMIN_MENU;

	return (
		<ul className='menu'>
			{Object.entries(menuItems).map(([key, value]) => {
				const isActive = selectedItem === value.path;
				const itemClass = isActive ? 'active' : '';
				const isOpen =
					openSubMenu ||
					selectedItem === PROFILE_ROUTES.BRAND_INFO ||
					selectedItem === PROFILE_ROUTES.PRODUCT_INFO ||
					selectedItem === PROFILE_ROUTES.GET_ON_BEACON;
				if (value.options) {
					return (
						<div key={key}>
							<div
								key={key}
								className={`tab-container title-submenu ${itemClass} ${isOpen ? 'active' : ''}`}
								onClick={() => {
									toggleSubMenu();
								}}>
								<li className={`tab-text `} key={key}>
									{value.title}
									<i className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} />
								</li>
							</div>
							<div className={`submenu ${isOpen ? 'show' : ''}`} key={`tab-submenu-${key}`}>
								<ul className='menu'>
									{isOpen &&
										Object.entries(value.options).map(([key, value]: any) => {
											const subMenuItemActive = selectedItem === value.path;
											const itemClassSub = subMenuItemActive ? 'active-sub' : '';
											return (
												<div
													key={`tab-container-${key}`}
													className={`tab-container ${itemClassSub}`}
													onClick={() => handleItemMenu(value.path)}>
													<li key={key} className={`tab-text`}>
														{value.title}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					);
				}
				return (
					<div
						key={'default' + key}
						className={`tab-container ${itemClass}`}
						onClick={() => handleItemMenu(value.path)}>
						<li key={key} className={`tab-text`}>
							{value.title}
						</li>
					</div>
				);
			})}
		</ul>
	);
};
