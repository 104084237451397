import { useEffect, useState } from 'react';
import './StatusBubbleComponent.scss';

interface StatusBubbleProps {
	status: Status;
}
interface StatusGroup {
	color: StatusColor;
	status: Status[];
}

export type Status =
	| 'Approved'
	| 'Live on Beacon'
	| 'Pending'
	| 'Not Published'
	| 'Inactive'
	| 'Not Approved'
	| 'Incomplete';

type StatusColor = 'green' | 'blue' | 'gray' | 'red';
export const StatusBubbleComponent: React.FC<StatusBubbleProps> = ({ status }) => {
	const [filled, setFilled] = useState<boolean>(false);
	const statusGroups: StatusGroup[] = [
		{ color: 'green', status: ['Approved', 'Live on Beacon'] },
		{ color: 'blue', status: ['Pending'] },
		{ color: 'gray', status: ['Not Published', 'Inactive'] },
		{ color: 'red', status: ['Not Approved', 'Incomplete'] }
	];
	const colorMapStatus = statusGroups.reduce((map, group) => {
		group.status.forEach(status => {
			map[status] = group.color;
		});
		return map;
	}, {} as Record<Status, StatusColor>);

	const statusColor: StatusColor = colorMapStatus[status] || 'gray';

	useEffect(() => {
		const interval = setTimeout(() => {
			setFilled(!filled);
		}, 1500);
		return () => clearInterval(interval);
	}, [filled]);
	return <div className={`bubble-status ${statusColor} ${filled ? 'filled' : 'normal'}`}>{status}</div>;
};
