import './DistributionFormComponent.scss';
import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
interface IDistributionForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}
const DistributionSchema = Yup.object().shape({
	// currentDistribution: Yup.boolean(),
	mapPolicy: Yup.boolean(),
	currentDistributionChannels: Yup.array(),
	currentRetailChannels: Yup.array(),
	keheRegion: Yup.array(),
	otherDistributorRegion: Yup.array(),
	UNFIRegion: Yup.array()
});
const DistributionFormComponent: React.FC<IDistributionForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'distribution-channels';
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);

	const { values, handleChange, handleReset, submitForm, isValid } = useFormik({
		initialValues: {
			mapPolicy: brandState.mapPolicy,
			currentDistributionChannels: brandState.currentDistributionChannels,
			currentRetailChannels: brandState.currentRetailChannels,
			keheRegion: brandState.keheRegion,
			otherDistributorRegion: brandState.otherDistributorRegion,
			UNFIRegion: brandState.UNFIRegion
		},
		onSubmit: values => {
			console.log(values);
			onUpdateProfileBrand('');
		},
		validationSchema: DistributionSchema
	});
	return (
		<div className='profile-distribution'>
			<div className='distribution-tab'>
				<TitleForms
					isEditing={isEditing === ID}
					isVisible={isVisible}
					onUpdate={() => onUpdateProfileBrand(state)}
					titleText='Distribution / Channels'
					toggleEditing={() => toggleEditing(ID)}
					toggleVisibility={toggleVisibility}></TitleForms>
				{isVisible &&
					(isEditing !== ID ? (
						<>
							<ContainerForms
								FormComponent1={
									<>
										<div>
											<LabelForms text='Select distributors you work with:'></LabelForms>
											{brandState.currentDistributionChannels.map(distributor => (
												<CheckboxFormComponent
													key={distributor.id}
													checkboxType='square'
													name={`currentDistributionChannels.${distributor.id}`}
													checkboxText={<>{distributor.name}</>}
													value={distributor.isActive}
													disabled={true}></CheckboxFormComponent>
											))}
										</div>
										<div>
											<LabelForms text='Select retail distribution channels'></LabelForms>
											{brandState.currentRetailChannels!.map(distributor => (
												<CheckboxFormComponent
													key={distributor.id}
													checkboxType='square'
													name={`currentDistributionChannels.${distributor.id}`}
													checkboxText={<>{distributor.name}</>}
													value={distributor.isActive}
													disabled={true}></CheckboxFormComponent>
											))}
										</div>

										<div>
											<LabelForms text='For KeHE, which Regions are you Available in?'></LabelForms>

											{brandState.keheRegion!.map(region => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`otherDistributorRegion.${region.id}`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={true}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
									</>
								}
								FormComponent2={
									<>
										{' '}
										<div>
											<LabelForms text='Do you Have a MAP Policy?'></LabelForms>
											<ButtonComponent
												onClick={() => {}}
												style='rounded-button tertiary toggle-button'
												text=''
												icon={<i className='ri-toggle-line' />}
												disabled
											/>
										</div>
										<div>
											<LabelForms text='UNFI Region'></LabelForms>
											{brandState.UNFIRegion!.map(region => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`UNFIRegion.${region.id}`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={true}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
										<div>
											<LabelForms text='For Other Distributors, which Regions are you Available in?'></LabelForms>
											{brandState.otherDistributorRegion!.map(region => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`otherDistributorRegion.${region.id}`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={true}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
									</>
								}></ContainerForms>
						</>
					) : (
						<>
							<ContainerForms
								FormComponent1={
									<>
										<div>
											<LabelForms text='Select distributors you work with'></LabelForms>
											{values.currentDistributionChannels!.map((distributor, index) => (
												<CheckboxFormComponent
													key={distributor.id}
													checkboxType='square'
													name={`currentDistributionChannels.[${index}].isActive`}
													checkboxText={<>{distributor.name}</>}
													value={distributor.isActive}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
										<div>
											<LabelForms text='Select retail distribution channels'></LabelForms>
											{values.currentRetailChannels!.map((distributor, index) => (
												<CheckboxFormComponent
													key={distributor.id}
													checkboxType='square'
													name={`currentRetailChannels.[${index}].isActive`}
													checkboxText={<>{distributor.name}</>}
													value={distributor.isActive}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
										<div>
											<LabelForms text='KeHe Region'></LabelForms>
											{values.keheRegion!.map((region, index) => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`keheRegion.[${index}].isActive`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
									</>
								}
								FormComponent2={
									<>
										{' '}
										<div>
											<LabelForms text='Do you Have a MAP Policy?'></LabelForms>
											<CheckboxFormComponent
												checkboxType='toggle'
												name='mapPolicy'
												value={values.mapPolicy}
												disabled={false}
												onChange={handleChange}></CheckboxFormComponent>
										</div>
										<div>
											<LabelForms text='Other Distributor Region'></LabelForms>
											{values.otherDistributorRegion!.map((region, index) => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`otherDistributorRegion.[${index}].isActive`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
										<div>
											<LabelForms text='UNFI Region'></LabelForms>
											{values.UNFIRegion!.map((region, index) => (
												<CheckboxFormComponent
													key={region.id}
													checkboxType='square'
													name={`UNFIRegion.[${index}].isActive`}
													checkboxText={<>{region.name}</>}
													value={region.isActive}
													disabled={false}
													onChange={handleChange}></CheckboxFormComponent>
											))}
										</div>
									</>
								}></ContainerForms>
							<ActionsForms
								onUpdate={() => submitForm()}
								onCancel={() => {
									onCancel();
									handleReset(null);
								}}
								isValid={isValid}
								toggleEditing={() => toggleEditing(null)}></ActionsForms>
						</>
					))}
			</div>
		</div>
	);
};

export default React.memo(DistributionFormComponent);
