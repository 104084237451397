export const otherServicesNeedsLabels = {
	contractManufacturingPrivateLabel: 'Contract Manufacturing / Legal Services',
	fulfillmentLogistics: 'Fulfillment / Logistics',
	packagingPlastics: 'Packaging / Plastics',
	sustainablePackaging: 'Sustainable Packaging',
	labelPrinting: 'Label Printing',
	qualityAssurance: 'Quality Assurance / Control / Lab Testing',
	certifyingAgentOrganic: 'Certifying Agent for GMPs Organic Gluten Free Non-GMO etc',
	formulationClinicalResearch: 'Formulation / R&D / Clinical Research',
	mediaMarketingAgency: 'PR / Media / Marketing Agency',
	manufacturingProcessingEquipment: 'Manufacturing / Processing Equipment',
	legalRegulatoryConsulting: 'Legal / Regulatory Consulting',
	contactlessSampling: 'Contactless Sampling',
	staffingServices: 'Staffing Services',
	financeAccountingPayrollServices: 'Finance / Accounting Payroll Services',
	financialInstitutionInvestmentBanking: 'Financial Institution/Investment Banking',
	investors: 'Investors',
	onlineBusinessSolutions: 'Online Business Solutions',
	artificialIntelligence: 'Artificial Intelligence',
	seekDistribution: 'Seek Distribution',
	seekBrokerWholesaler: 'Seek Broker/Wholesaler',
	otherNotListedHere: 'Other - Not listed here',
	noneAbove: 'None of the above'
};

export const otherServicesNeedsArray = [
	{ id: 'contractManufacturingPrivateLabel', label: 'Contract Manufacturing / Legal Services' },
	{ id: 'fulfillmentLogistics', label: 'Fulfillment / Logistics' },
	{ id: 'packagingPlastics', label: 'Packaging / Plastics' },
	{ id: 'sustainablePackaging', label: 'Sustainable Packaging' },
	{ id: 'labelPrinting', label: 'Label Printing' },
	{ id: 'qualityAssurance', label: 'Quality Assurance / Control / Lab Testing' },
	{
		id: 'certifyingAgentOrganic',
		label: 'Certifying Agent for GMPs Organic Gluten Free Non-GMO etc'
	},
	{ id: 'formulationClinicalResearch', label: 'Formulation / R&D / Clinical Research' },
	{ id: 'mediaMarketingAgency', label: 'PR / Media / Marketing Agency' },
	{ id: 'manufacturingProcessingEquipment', label: 'Manufacturing / Processing Equipment' },
	{ id: 'legalRegulatoryConsulting', label: 'Legal / Regulatory Consulting' },
	{ id: 'contactlessSampling', label: 'Contactless Sampling' },
	{ id: 'staffingServices', label: 'Staffing Services' },
	{ id: 'financeAccountingPayrollServices', label: 'Finance / Accounting Payroll Services' },
	{
		id: 'financialInstitutionInvestmentBanking',
		label: 'Financial Institution/Investment Banking'
	},
	{ id: 'investors', label: 'Investors' },
	{ id: 'onlineBusinessSolutions', label: 'Online Business Solutions' },
	{ id: 'artificialIntelligence', label: 'Artificial Intelligence' },
	{ id: 'seekDistribution', label: 'Seek Distribution' },
	{ id: 'seekBrokerWholesaler', label: 'Seek Broker/Wholesaler' },
	{ id: 'otherNotListedHere', label: 'Other - Not listed here' },
];
