import './SocialMediaFormComponent.scss';
import { useEffect, useState } from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { validationMessages, validationRegex } from '../../../../common/constants';

interface ISocialMediaForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}

const BrandSocialMediaSchema = Yup.object().shape({
	brandYoutube: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(120, validationMessages.max_value),
	brandInstagram: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value),
	brandTiktok: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value),
	brandTwitter: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(75, validationMessages.max_value),
	brandFacebook: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value),
	brandLinkedin: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(100, validationMessages.max_value)
});

const SocialMediaFormComponent: React.FC<ISocialMediaForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'social-media-details';
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);

	const { errors, values, handleChange, handleReset, submitForm, isValid } = useFormik({
		initialValues: {
			brandYoutube: brandState.brandYoutube,
			brandInstagram: brandState.brandInstagram,
			brandTiktok: brandState.brandTiktok,
			brandTwitter: brandState.brandTwitter,
			brandFacebook: brandState.brandFacebook,
			brandLinkedin: brandState.brandLinkedin
		},
		onSubmit: values => {
			console.log(values);
			onUpdateProfileBrand('');
		},
		validationSchema: BrandSocialMediaSchema
	});

	return (
		<div className='social-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => onUpdateProfileBrand(state)}
				titleText='Social Media'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='YouTube'></LabelForms>
										<p className={state?.brandYoutube ? '' : 'blank-field'}>
											{state?.brandYoutube}
										</p>
									</div>
									<div>
										<LabelForms text='Instagram'></LabelForms>
										<p className={state?.brandInstagram ? '' : 'blank-field'}>
											{state?.brandInstagram}
										</p>
									</div>
									<div>
										<LabelForms text='TikTok'></LabelForms>

										<p className={state?.brandTiktok ? '' : 'blank-field'}>{state?.brandTiktok}</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Twitter / X'></LabelForms>
										<p className={state?.brandTwitter ? '' : 'blank-field'}>
											{state?.brandTwitter}
										</p>
									</div>
									<div>
										<LabelForms text='Facebook'></LabelForms>
										<p className={state?.brandFacebook ? '' : 'blank-field'}>
											{state?.brandFacebook}
										</p>
									</div>
									<div>
										<LabelForms text='LinkedIn'></LabelForms>
										<p className={state?.brandLinkedin ? '' : 'blank-field'}>
											{state?.brandLinkedin}
										</p>
									</div>
								</>
							}
						/>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms text='YouTube'></LabelForms>
										<TextInputComponent
											error={errors.brandYoutube?.length! > 0}
											errorHelperText={<div>{errors.brandYoutube}</div>}
											placeholder='Youtube'
											name='brandYoutube'
											onChange={handleChange}
											value={values.brandYoutube!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Instagram'></LabelForms>
										<TextInputComponent
											error={errors.brandInstagram?.length! > 0}
											errorHelperText={<div>{errors.brandInstagram}</div>}
											placeholder='Instagram'
											name='brandInstagram'
											onChange={handleChange}
											value={values.brandInstagram!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='TikTok'></LabelForms>
										<TextInputComponent
											error={errors.brandTiktok?.length! > 0}
											errorHelperText={<div>{errors.brandTiktok}</div>}
											placeholder='TikTok'
											name='brandTiktok'
											onChange={handleChange}
											value={values.brandTiktok!}></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='Twitter / X'></LabelForms>
										<TextInputComponent
											error={errors.brandTwitter?.length! > 0}
											errorHelperText={<div>{errors.brandTwitter}</div>}
											placeholder='Twitter / X'
											name='brandTwitter'
											onChange={handleChange}
											value={values.brandTwitter!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Facebook'></LabelForms>
										<TextInputComponent
											error={errors.brandFacebook?.length! > 0}
											errorHelperText={<div>{errors.brandFacebook}</div>}
											placeholder='Facebook'
											name='brandFacebook'
											onChange={handleChange}
											value={values.brandFacebook!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='LinkedIn'></LabelForms>
										<TextInputComponent
											error={errors.brandLinkedin?.length! > 0}
											errorHelperText={<div>{errors.brandLinkedin}</div>}
											placeholder='LinkedIn'
											name='brandLinkedin'
											onChange={handleChange}
											value={values.brandLinkedin!}></TextInputComponent>
									</div>
								</>
							}></ContainerForms>
						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(SocialMediaFormComponent);
