import { useEffect, useState } from 'react';
import './AccountFormComponent.scss';
import {
	CountryType,
	StateType,
	UserProfileData
} from '../../../authentication/user-profile/model';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import SelectFormComponent from '../../GenericBaseForms/SelectFormComponent/SelectFormComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
export interface IAccountForm {
	userState: UserProfileData;
	onUpdateProfile: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}

const AccountSchema = Yup.object().shape({
	first_name: Yup.string().min(2, 'First name is too short').required('First name is required'),
	last_name: Yup.string().min(2, 'Last name is too short').required('Last name is required'),
	email: Yup.string().email().min(2, 'Email is required'),
	country: Yup.object(),
	country_state: Yup.object()
});

export const AccountFormComponent: React.FC<IAccountForm> = ({
	userState,
	onUpdateProfile,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const [countriesList, setCountriesList] = useState<CountryType[]>([]);
	const [countryStates, setCountryStates] = useState<CountryType[]>([]);
	const [statesOptions, setStatesOptions] = useState<StateType[]>([]);
	const ID = 'account-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const { errors, values, handleChange, handleReset, submitForm, setFieldValue, isValid } =
		useFormik({
			initialValues: {
				first_name: userState.first_name,
				last_name: userState.last_name,
				email: userState.business_email,
				country: {
					id: userState.country,
					name: userState.country_name
				},
				country_state: {
					id: userState.state,
					name: userState.state_name
				}
			},
			onSubmit: values => {
				onUpdateProfile({
					first_name: values.first_name,
					last_name: values.last_name,
					business_email: values.email,
					country: values.country.id,
					country_name: values.country.name,
					state: values.country_state.id,
					state_name: values.country_state.name
				});
			},
			validationSchema: AccountSchema
		});

	useEffect(() => {
		if (window.IIRISProfileMgr) {
			window.IIRISProfileMgr.getTaxonomyData('countries?locale=en')?.then((data: any) => {
				setCountriesList(data.data);

			});
		}
	}, [window.IIRISProfileMgr]);

	useEffect(() => {
		if (countriesList.length > 0) {
			const countryStates = countriesList.filter(country => country.states.length > 0);
			setCountryStates(countryStates);
		}
	}, [countriesList]);
	useEffect(() => {
		if (countriesList.length > 0) {
			const element = countriesList.find(item => item.id === values.country.id);
			if (element && element.states.length > 0) {
				setStatesOptions(element.states);
			} else {
				setStatesOptions([]);
			}
		}
	}, [values.country, countryStates, countriesList]);
	return (
		<div className='account-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				isValid={isValid}
				onUpdate={() => submitForm()}
				titleText='Account Details'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='First Name'></LabelForms>
										<p>{userState.first_name}</p>
									</div>
									<div>
										<LabelForms text='Last Name'></LabelForms>
										<p>{userState.last_name}</p>
									</div>
									<div>
										<LabelForms text='Email'></LabelForms>
										<p>{userState.business_email}</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Country'></LabelForms>
										<p>{userState.country_name}</p>
									</div>
									{userState.state && (
										<div>
											<LabelForms text='State / Province'></LabelForms>
											<p>{userState.state_name}</p>
										</div>
									)}
								</>
							}></ContainerForms>
					</>
				) : (
					<>
						{' '}
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms text='First Name'></LabelForms>
										<TextInputComponent
											error={errors.first_name?.length! > 0}
											errorHelperText={<div>{errors.first_name}</div>}
											placeholder='First Name'
											name='first_name'
											onChange={handleChange}
											value={values.first_name!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Last Name'></LabelForms>
										<TextInputComponent
											disabled={false}
											error={false}
											placeholder='Last Name'
											name='last_name'
											onChange={handleChange}
											value={values.last_name!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Email'></LabelForms>
										<TextInputComponent
											disabled
											placeholder='Email'
											name='email'
											onChange={handleChange}
											value={values.email!}></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='Country'></LabelForms>
										<SelectFormComponent
											items={countriesList}
											name='country'
											onChange={setFieldValue}
											value={values.country}></SelectFormComponent>
									</div>
									{statesOptions.length > 0 && (
										<div>
											<LabelForms text='State / Province'></LabelForms>
											<SelectFormComponent
												items={statesOptions}
												name='country_state'
												onChange={setFieldValue}
												value={values.country_state}></SelectFormComponent>
										</div>
									)}
								</>
							}
						/>
						<ActionsForms
							onUpdate={() => submitForm()}
							toggleEditing={() => toggleEditing(null)}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}></ActionsForms>
					</>
				))}
		</div>
	);
};
