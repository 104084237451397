import { PROFILE_ROUTES } from '../../../common/constants';
export interface IMenuItemOptions {
	[key: string]: number | IMenuItem;
}

export interface IMenuItem {
	title?: string;
	options?: IMenuItemOptions;
	path?: string;
}

export interface IMenuProps {
	onSelectedItem: (index: any) => void;
	selectedItem: string;
}

export const DEFAULT_USER_MENU: IMenuItemOptions = {
	profile: { title: 'Profile', path: PROFILE_ROUTES.PROFILE },
	favorites: { title: 'Favorites', path: PROFILE_ROUTES.FAVORITES },
	getOnBeacon: { title: 'Getting Started', path: PROFILE_ROUTES.GET_ON_BEACON },
	onboardFAQ: { title: 'FAQ', path: PROFILE_ROUTES.ONBOARD_FAQ }
};

export const DEFAULT_FULL_USER_MENU: IMenuItemOptions = {
	profile: { title: 'Profile', path: PROFILE_ROUTES.PROFILE },
	ingredients: { title: 'Ingredient Templates', path: PROFILE_ROUTES.INGREDIENTS },
	favorites: { title: 'Favorites', path: PROFILE_ROUTES.FAVORITES },
	getOnBeacon: { title: 'Getting Started', path: PROFILE_ROUTES.GET_ON_BEACON },
	onboardFAQ: { title: 'FAQ', path: PROFILE_ROUTES.ONBOARD_FAQ }
};
export const USER_ADMIN_MENU: IMenuItemOptions = {
	profile: { title: 'Profile', path: PROFILE_ROUTES.PROFILE },
	ingredients: { title: 'Ingredient Templates', path: PROFILE_ROUTES.INGREDIENTS },
	favorites: { title: 'Favorites', path: PROFILE_ROUTES.FAVORITES },
	brandOnBeacon: {
		title: 'My Brand on Beacon',
		options: {
			getOnBeacon: { title: 'Getting Started', path: PROFILE_ROUTES.GET_ON_BEACON },
			brandinfo: { title: 'Brand Info', path: PROFILE_ROUTES.BRAND_INFO },
			productinfo: { title: 'Product Info', path: PROFILE_ROUTES.PRODUCT_INFO }
		}
	},
	onboardFAQ: { title: 'FAQ', path: PROFILE_ROUTES.ONBOARD_FAQ }
};
