import './BrandFormComponent.scss';
import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { BrandInfo, RootState } from '../../../../redux/AppReducer';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenType } from '../../../../common/view-model';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { CountryType } from '../../../authentication/user-profile/model';
import SelectFormComponent from '../../GenericBaseForms/SelectFormComponent/SelectFormComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { validationMessages, validationRegex } from '../../../../common/constants';
import {
	validateExactLengthMessage,
	validateMessageMax
} from '../../../../helpers/formFieldValidations';
interface IBrandForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}
const BrandFormSchema = Yup.object().shape({
	brandHQCity: Yup.string()
		.min(4, validationMessages.min_chars)
		.required(validationMessages.field_required),
	brandHQState: Yup.object({
		name: Yup.string(),
		id: Yup.string()
	}),
	brandHQCountry: Yup.object({
		name: Yup.string(),
		id: Yup.string()
	}),
	brandYearFound: Yup.number()
		.test(
			'length',
			validateExactLengthMessage('4 digits'),
			val => val !== undefined && val?.toString().length === 4
		)
		.max(new Date().getFullYear(), validateMessageMax(new Date().getFullYear().toString())),
	brandMission: Yup.string().required().max(3000, validationMessages.max_value),
	brandStory: Yup.string().required().max(3000, validationMessages.max_value),
	privateLabelServices: Yup.boolean(),
	brandVideo: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(150, validationMessages.max_value),
	brandWebsite: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(150, validationMessages.max_value),
	ownedByHoldingCompany: Yup.boolean(),
	holdingCompany: Yup.string().notRequired().max(50, validationMessages.max_value),
	ownedByParentCompany: Yup.boolean(),
	parentCompany: Yup.string().notRequired().max(50, validationMessages.max_value),
	otherServicesNeeds: Yup.array(),
	processingTechniques: Yup.array()
});
const LARGE_MOBILE = 744;
const SMALL_MOBILE = 500;
const BrandFormComponent: React.FC<IBrandForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const ID = 'brand-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [storyExpanded, setStoryExpanded] = useState<boolean>(false);
	const [missionExpanded, setMissionExpanded] = useState<boolean>(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	const [charactersShown, setCharactersShown] = useState<number>(0);

	const [countriesList, setCountriesList] = useState<CountryType[]>([]);
	const [countryStates, setCountryStates] = useState<CountryType[]>([]);
	const [statesOptions, setStatesOptions] = useState<any[]>([]);

	const { errors, values, handleChange, handleReset, submitForm, setFieldValue, isValid } =
		useFormik({
			initialValues: {
				brandHQCity: brandState.brandHQCity,
				brandHQCountry: {
					id: '',
					name: brandState.brandHQCountry
				},
				brandHQState: {
					id: '',
					name: brandState.brandHQState
				},
				brandYearFound: brandState.brandYearFound,
				brandMission: brandState.brandMission,
				brandStory: brandState.brandStory,
				privateLabelServices: brandState.privateLabelServices,
				brandVideo: brandState.brandVideo,
				brandWebsite: brandState.brandWebsite,
				ownedByHoldingCompany: brandState.ownedByHoldingCompany,
				holdingCompany: brandState.holdingCompany,
				ownedByParentCompany: brandState.ownedByParentCompany,
				parentCompany: brandState.parentCompany,
				otherServicesNeeds: brandState.otherServicesNeeds,
				processingTechniques: brandState.processingTechniques
			},
			onSubmit: values => {
				console.log(values);
				onUpdateProfileBrand('');
			},
			validationSchema: BrandFormSchema
		});

	const handleToggle = (type: string) => {
		if (type === 'story') {
			setStoryExpanded(!storyExpanded);
		} else {
			setMissionExpanded(!missionExpanded);
		}
	};

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.TABLET) {
			setCharactersShown(25);
		} else if (LARGE_MOBILE < windowsSize && windowsSize > SMALL_MOBILE) {
			setCharactersShown(40);
		} else if (SMALL_MOBILE < windowsSize) {
			setCharactersShown(26);
		}
	}, [deviceScreen, windowsSize]);

	useEffect(() => {
		if (window.IIRISProfileMgr) {
			window.IIRISProfileMgr.getTaxonomyData('countries?locale=en')?.then((data: any) => {
				setCountriesList(data.data);
			});
		}
	}, [window.IIRISProfileMgr]);

	useEffect(() => {
		const countryStates = countriesList.filter(country => country.states.length > 0);
		setCountryStates(countryStates);
	}, [countriesList]);
	useEffect(() => {
		const element = countriesList.find(item => item.name === values.brandHQCountry.name);
		if (element && element.states.length > 0) {
			setStatesOptions(element.states);
		} else {
			setStatesOptions([]);
		}
	}, [values.brandHQCountry, countryStates, countriesList]);
	return (
		<div className='brand-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => {}}
				titleText='Brand'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}></TitleForms>

			{isVisible &&
				(isEditing !== ID ? (
					<>
						<p className='description-form'>
							Some information will be seen by retailers via your brand profile page on Beacon
							Discovery. Please make sure that all information is accurate and up-to-date.
						</p>
						<ContainerForms
							FormComponent1={
								<>
									<div className='brand-logo-container'>
										<LabelForms text='Brand Logo'></LabelForms>

										<div className='brand-image'>
											<div className='image-container'>
												<img src={brandState?.brandLogo} alt='brand' />
											</div>
										</div>
									</div>
									<div>
										<LabelForms text='Headquarters Country Location'></LabelForms>
										<p>{brandState?.brandHQCountry}</p>
									</div>
									<div>
										<LabelForms text='Headquarters State Location'></LabelForms>
										<p>{brandState?.brandHQState}</p>
									</div>
									<div>
										<LabelForms text='Headquarters City Location'></LabelForms>
										<p>{brandState?.brandHQCity}</p>
									</div>
									<div>
										<LabelForms text='Year Founded'></LabelForms>
										<p>{brandState?.brandYearFound}</p>
									</div>
									<div className='mission-container'>
										<LabelForms text='Brand Mission'></LabelForms>
										<div>
											<p>
												{missionExpanded
													? brandState?.brandMission
													: `${brandState?.brandMission?.substring(0, charactersShown)}...`}
											</p>
											<button onClick={() => handleToggle('mission')}>
												{missionExpanded ? '' : 'Expand'}
											</button>
										</div>
									</div>
									<div className='story-container'>
										<LabelForms text='Brand Story'></LabelForms>
										<div>
											<p>
												{storyExpanded
													? brandState?.brandStory
													: `${brandState?.brandStory?.substring(0, charactersShown)}...`}
											</p>
											<button onClick={() => handleToggle('story')}>
												{storyExpanded ? '' : 'Expand'}
											</button>
										</div>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div className='blank-space' />
									<div>
										<LabelForms text='Do you Offer Private Label Services?'></LabelForms>

										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-line' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Brand Video'></LabelForms>
										<p>{brandState?.brandFacebook}</p>
									</div>
									<div>
										<LabelForms text='Are you Owned by a Holding Company?'></LabelForms>
										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Holding Company Name'></LabelForms>
										<p>{brandState.holdingCompany}</p>
									</div>
									<div>
										<LabelForms text='Are you Owned by a Parent Company?'></LabelForms>
										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Parent Company Name'></LabelForms>
										<p>{brandState.brandHQCountry}</p>
									</div>
									<div>
										<LabelForms text='What are your Current Service Provider Needs?'></LabelForms>
										{values.otherServicesNeeds?.map((service, index) => {
											return (
												<CheckboxFormComponent
													key={index + `${service.id}`}
													checkboxType='square'
													name={`processingTechniques.${index}.value`}
													checkboxText={
														<>
															<p className='title-service'>{service.label}</p>{' '}
														</>
													}
													value={service.isActive}
													disabled={true}
													onChange={handleChange}></CheckboxFormComponent>
											);
										})}
									</div>
									<div>
										<LabelForms text='Are any of your Products Made Using these Processing Techniques?'></LabelForms>
										{values.processingTechniques!.map((technique, index) => (
											<div className='checkbox-container' key={index}>
												<CheckboxFormComponent
													key={index + `${technique.id}`}
													checkboxType='square'
													name={`processingTechniques.${index}.value`}
													checkboxText={
														<div className='processing-info-container'>
															<p className='title-technique'>{technique.name}</p>
															{' - '}
															<p className='description-technique'>{technique.description}</p>
														</div>
													}
													value={technique.isActive}
													disabled={true}
													onChange={handleChange}></CheckboxFormComponent>
											</div>
										))}
									</div>
								</>
							}></ContainerForms>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									<div className='brand-logo-container'>
										<LabelForms text='Brand Logo'></LabelForms>
										<div className='brand-image'>
											<div className='image-container'>
												<img src={brandState?.brandLogo} alt='brand' />
											</div>
										</div>
									</div>

									<div>
										<LabelForms text='Headquarters Country Location'></LabelForms>
										<SelectFormComponent
											items={countriesList}
											name='brandHQCountry'
											onChange={setFieldValue}
											value={values.brandHQCountry}></SelectFormComponent>
									</div>
									<div>
										<LabelForms text='Headquarters State Location'></LabelForms>
										<SelectFormComponent
											items={statesOptions}
											name='brandHQState'
											onChange={setFieldValue}
											value={values.brandHQState}></SelectFormComponent>
									</div>
									<div>
										<LabelForms text='Headquarters City Location'></LabelForms>
										<TextInputComponent
											error={errors.brandHQCity?.length! > 0}
											errorHelperText={<div>{errors.brandHQCity}</div>}
											placeholder='Headquarters City Location'
											name='brandHQCity'
											onChange={handleChange}
											value={values.brandHQCity!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Year Founded'></LabelForms>
										<TextInputComponent
											error={errors.brandYearFound?.length! > 0}
											errorHelperText={<div>{errors.brandYearFound}</div>}
											placeholder='Year Founded'
											name='brandYearFound'
											onChange={handleChange}
											value={values.brandYearFound!}
											type='number'></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Mission'></LabelForms>
										<TextInputComponent
											error={errors.brandMission?.length! > 0}
											errorHelperText={<div>{errors.brandMission}</div>}
											placeholder='Brand Mission'
											name='brandMission'
											onChange={handleChange}
											value={values.brandMission!}
											type='textarea'></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Story'></LabelForms>
										<TextInputComponent
											error={errors.brandStory?.length! > 0}
											errorHelperText={<div>{errors.brandStory}</div>}
											placeholder='Brand Story'
											name='brandStory'
											onChange={handleChange}
											value={values.brandStory!}
											type='textarea'></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='Do you Offer Private Label Services?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='privateLabelServices'
											value={values.privateLabelServices!}
											disabled={false}
											onChange={handleChange}></CheckboxFormComponent>
									</div>
									<div>
										<LabelForms text='Brand Video'></LabelForms>
										<TextInputComponent
											error={errors.brandVideo?.length! > 0}
											errorHelperText={<div>{errors.brandVideo}</div>}
											placeholder='Brand Video'
											name='brandVideo'
											onChange={handleChange}
											value={values.brandVideo!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Website'></LabelForms>
										<TextInputComponent
											error={errors.brandWebsite?.length! > 0}
											errorHelperText={<div>{errors.brandWebsite}</div>}
											placeholder='Brand Website'
											name='brandWebsite'
											onChange={handleChange}
											value={values.brandWebsite!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Are you Owned by a Holding Company?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='ownedByHoldingCompany'
											value={values.ownedByHoldingCompany!}
											disabled={false}
											onChange={handleChange}></CheckboxFormComponent>
									</div>
									{values.ownedByHoldingCompany === true && (
										<div>
											<LabelForms
												text='Holding Company Name'
												toolTipText={
													<>
														A holding company invests in operating companies that actually produce
														goods or offer services.
													</>
												}
												icon={<i className='ri-question-line' />}
												toolTipPosition='right'></LabelForms>
											<TextInputComponent
												error={errors.holdingCompany?.length! > 0}
												errorHelperText={<div>{errors.holdingCompany}</div>}
												placeholder='Holding Company Name'
												name='holdingCompany'
												onChange={handleChange}
												value={values.holdingCompany!}></TextInputComponent>
										</div>
									)}
									<div>
										<LabelForms text='Are you Owned by a Parent Company?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='ownedByParentCompany'
											value={values.ownedByParentCompany!}
											disabled={false}
											onChange={handleChange}></CheckboxFormComponent>
									</div>
									{values.ownedByParentCompany === true && (
										<div>
											<LabelForms
												text='Parent Company Name'
												toolTipText={
													<>
														A parent company has its own operations and also owns other companies.
													</>
												}
												icon={<i className='ri-question-line' />}
												toolTipPosition='right'></LabelForms>
											<TextInputComponent
												error={errors.parentCompany?.length! > 0}
												errorHelperText={<div>{errors.parentCompany}</div>}
												placeholder='Parent Company Name'
												name='parentCompany'
												onChange={handleChange}
												value={values.parentCompany!}></TextInputComponent>
										</div>
									)}
									<div>
										<LabelForms text='What are your Current Service Provider Needs?'></LabelForms>
										{values.otherServicesNeeds!.map((service, index) => (
											<CheckboxFormComponent
												key={index + service.id}
												checkboxType='square'
												name={`otherServicesNeeds.[${index}].isActive`}
												checkboxText={<>{values.otherServicesNeeds![index].label}</>}
												value={service.isActive}
												disabled={false}
												onChange={handleChange}></CheckboxFormComponent>
										))}
									</div>

									<div>
										<LabelForms text='Are any of your Products Made Using these Processing Techniques?'></LabelForms>
										{values.processingTechniques!.map((technique, index) => (
											<CheckboxFormComponent
												key={index + technique.id}
												checkboxType='square'
												name={`processingTechniques.[${index}].isActive`}
												checkboxText={
													<div className='processing-info-container'>
														<p className='title-technique'>{technique.name}</p>
														{' - '}
														<p className='description-technique'>{technique.description}</p>
													</div>
												}
												value={technique.isActive}
												disabled={false}
												onChange={handleChange}></CheckboxFormComponent>
										))}
									</div>
								</>
							}></ContainerForms>
						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(BrandFormComponent);
