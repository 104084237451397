import { FC, useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { IngredientsTemplate, ScreenType } from '../../common/view-model';
import { GET_INGREDIENT_TEMPLATES } from '../../graphql/queries/ingredientTemplates';
import { getTemplateIngredientsByPurpose } from '../../helpers/transformers';
import BrandOnboard from '../../pages/BrandOnboard';
import { ApolloActions } from '../../redux/ApolloActions';
import { AppActions } from '../../redux/AppActions';
import { BrandInfo, EngagementDashboardState, RootState } from '../../redux/AppReducer';
import { EngagementDashboardActions } from '../../redux/engagementDashboard/EngagementDashboardActions';
import { UserActions } from '../../redux/UserActions';
import { UserProfileData } from '../authentication/user-profile/model';
import { EngagementDashboardComponent } from '../EngagementDashboard/EngagementDashboardComponent';
import NewProfileIngredientsComponent from './NewProfileIngredients/NewProfileIngredientsComponent';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import { MenuProfileComponent } from '../UI/MenuProfileComponent/MenuProfileComponent';
import { WarningFormOverlayComponent } from '../WarningFormOverlay/WarningFormOverlayComponent';
import './NewProfileComponent.scss';
import FavoritesPage from '../../pages/Favorites/index';
import { NewDetailedTemplateComparisonComponent } from './NewDetailTemplateComparison/NewDetailedTemplateComparisonComponent';
import BrandFormComponent from '../forms/BrandForms/BrandForm/BrandFormComponent';
import ContactFormComponent from '../forms/BrandForms/ContactForm/ContactFormComponent';
import DistributionFormComponent from '../forms/BrandForms/DistributionForm/DistributionFormComponent';
import SocialMediaFormComponent from '../forms/BrandForms/SocialMediaForm/SocialMediaFormComponent';
import StandardsApplicationComponent from '../forms/BrandForms/StandardsApplication/StandardsApplicationComponent';
import { AccountFormComponent } from '../forms/ProfileForms/AccountForm/AccountFormComponent';
import CompanyFormComponent from '../forms/ProfileForms/CompanyForm/CompanyFormComponent';
import ProductDetailsFormComponent from '../forms/ProductForms/ProductDetailsForm/ProductDetailsFormComponent';
import ProductStandardsFormComponent from '../forms/ProductForms/ProductStandardsForm/ProductStandardsFormComponent';
import ProductViolationsFormComponent from '../forms/ProductForms/ProductViolationsForm/ProductViolationsFormComponent';
import ProductSustainabilityFormComponent from '../forms/ProductForms/ProductSustainabilityForm/ProductSustainabilityFormComponent';
import ProductCertificationsFormComponent from '../forms/ProductForms/ProductCertificationsForm/ProductCertificationsFormComponent';
import ProductAboutFormComponent from '../forms/ProductForms/ProductAboutForm/ProductAboutFormcomponent';
import { brandStates, PROFILE_ROUTES } from '../../common/constants';
import { Status, StatusBubbleComponent } from '../UI/StatusBubbleComponent/StatusBubbleComponent';
import FAQComponent from './FAQComponent/FAQComponent';

export interface INewUserProfileProps {
	setProfileLayout: (value: boolean) => void;
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => void;
	getEngagementDashboardData: (brandId: string) => void;
	engagementDashboardState: EngagementDashboardState | undefined;
	ingredientTemplates?: IngredientsTemplate[];
	ingredientsTemplateId?: number;
	getTemplates: () => void;
}
interface IOpenStates {
	collapsed: boolean;
	warnings: string[];
}
const NewUserProfile: FC<INewUserProfileProps> = ({
	setProfileLayout,
	setIsOnFavoriteTab,
	engagementDashboardState,
	getEngagementDashboardData,
	getTemplates,
	ingredientTemplates,
	ingredientsTemplateId
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const brandState = useSelector((state: RootState) => state.appState.userState?.brandInfo);
	const productsState = useSelector((state: RootState) => state.appState.userState?.products);
	const [state, setState] = useState<UserProfileData>();
	const [toggleState, setToggleState] = useState(location.hash.replace('#', '') || '1');
	const favoritesPageRef = useRef<HTMLDivElement>(null);
	const [detailCurrentTab, setDetailCurrentTab] = useState(ingredientsTemplateId!);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [currentTabIndex, setCurrentTabIndex] = useState(ingredientsTemplateId!);
	const [previewTab, setPreviewTab] = useState(-1);
	const [showDetails, setShowDetails] = useState(false);
	const [ingredientsEditing, setIngredientsEditing] = useState(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const [menuScreenType, setMenuScreenType] = useState<string>('default');
	const [prevIndex, setPrevIndex] = useState<number | undefined>(undefined);
	const [openStates, setOpenStates] = useState<IOpenStates[]>([]);
	const [activeEditing, setActiveEditing] = useState<string | null>(null);
	const [tempData, setTempData] = useState<UserProfileData>();
	const [tempBrand, setTempBrand] = useState<BrandInfo>();
	const [templates, setTemplates] = useState<IngredientsTemplate[]>([]);
	const [filled, setFilled] = useState(true);
	const navigate = useNavigate();

	const currentStatusBrand = brandStates.find(status => status.name === brandState?.status);
	const titleCallback = useCallback(
		() => dispatch(AppActions.setAppTitle('User profile')),
		[dispatch]
	);
	const favouriteCallback = useCallback(
		(flag: boolean) => dispatch(AppActions.setAppFavourite(flag)),
		[dispatch]
	);

	useEffect(() => {
		if (userState) {
			setTempData(userState);
		}
	}, [userState]);

	useEffect(() => {
		if (brandState) {
			setTempBrand(brandState);
		}
	}, [brandState]);

	useEffect(() => {
		if (ingredientTemplates) {
			ingredientTemplates = ingredientTemplates.map(template => ({
				...template,
				purpose: getTemplateIngredientsByPurpose(template)
			}));

			setTemplates(ingredientTemplates!);
		} else {
			getTemplates();
		}
	}, [ingredientTemplates]);

	useEffect(() => {
		if (productsState) {
			setOpenStates(
				productsState.map((product, index) => ({
					collapsed: false,
					warnings:
						index === 1
							? ['allergen-warning-missing']
							: index % 2 === 0
							? ['allergen-warning-missing', 'claims-tm', 'ingredients-list-missing']
							: index % 3 === 0
							? ['organic-certifying-agent-missing', 'disease-claims']
							: []
				}))
			);
		}
	}, [productsState]);

	useEffect(() => {
		if (userState) {
			setState(userState);
		}
	}, [userState]);
	useEffect(() => {
		setProfileLayout(true);
	}, []);
	useEffect(() => {
		const section = location.pathname.replace(/^\/+/, '');
		setToggleState(section);
	}, [location.pathname]);
	useEffect(() => {
		titleCallback();
		return () => {
			setProfileLayout(false);
		};
	}, []);

	useEffect(() => {
		setIsOnFavoriteTab(
			(favoritesPageRef.current && favoritesPageRef.current.classList.contains('active-content')) ||
				false
		);
	}, [setIsOnFavoriteTab, toggleState]);

	useEffect(() => {
		setState(userState);
	}, [toggleState]);

	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setMenuScreenType('default');
			setMenuOpen(true);
		} else if (deviceScreen === ScreenType.TABLET) {
			setMenuScreenType('tablet');
			setMenuOpen(false);
		} else if (deviceScreen === ScreenType.MOBILE) {
			setMenuScreenType('mobile');
			setMenuOpen(false);
		}
	}, [deviceScreen]);

	useEffect(() => {
		if (toggleState === '7') {
			const interval = setTimeout(() => {
				setFilled(!filled);
			}, 1500);
			return () => clearInterval(interval);
		}
	}, [filled, toggleState]);

	const toggleTab = (index: any) => {
		setToggleState(index);
		if (index === 'favorites') {
			favouriteCallback(true);
		} else {
			favouriteCallback(false);
		}

		navigate(`/${index}`, { replace: true });
	};
	const updateProfile = (userState: any) => {
		dispatch(
			UserActions.updateUserProfile({
				companyDetails: {
					name: userState?.company_name,
					website: userState?.company_website,
					userType: userState?.user_type,
					jobType: userState?.job_type?.split(' ').join(''),
					isEditing: true
				},
				userProfile: {
					business_email: userState?.business_email,
					business_type: userState?.business_type,
					company: userState?.company,
					country: userState?.country,
					country_name: userState?.country_name,
					first_name: userState?.first_name,
					is_social_login: false,
					job_function: userState?.job_function,
					id: userState?.id,
					job_title: userState?.job_title,
					last_name: userState?.last_name,
					social_accounts: userState?.social_accounts,
					state: userState?.state,
					state_name: userState?.state_name
				}
			})
		);
		setActiveEditing(null);
	};
	const updateBrand = () => {
		dispatch(UserActions.updateUserBrand({}));
		setActiveEditing(null);
	};
	const updateProduct = () => {
		setActiveEditing(null);
	};
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const moveToStepHandler = (index: number) => {
		setPrevIndex(index);
		const targetElement = document.getElementById('target');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		if (ingredientsEditing) {
			setCurrentTabIndex(index);
			setShowDetails(true);
			if (prevIndex === index) {
				setPrevIndex(undefined);
			}
		} else {
			setDetailCurrentTab(index);
			setPreviewTab(index);
			setShowDetails(true);
		}
	};

	const toggleEditing = (formId: string | null) => {
		if (activeEditing !== null && formId !== null) {
			setShowWarning(true);
			return;
		}
		setActiveEditing(formId);
	};
	const cancelUpdate = () => {
		setTempData(userState);
		setTempBrand(brandState);
		setActiveEditing(null);
	};

	const toggleOpen = (index: number) => {
		const newOpenStates = openStates.map((state, indexState) =>
			index === indexState
				? {
						...state,
						collapsed: !state.collapsed
				  }
				: state
		);
		setOpenStates(newOpenStates);
	};
	return (
		<div data-testid='profile-container' className={`new-page-container`}>
			<div className='new-profile-container'>
				{showWarning && (
					<div className={`overlay ${showWarning ? 'open-warning' : ''}`}>
						<WarningFormOverlayComponent
							cancelUpdate={cancelUpdate}
							closeOverlay={() => setShowWarning(false)}
							formData={tempData}
							updateData={updateProfile}
						></WarningFormOverlayComponent>
					</div>
				)}
				{menuScreenType !== 'default' && (
					<div className={`overlay ${menuOpen ? 'open' : ''}`}>
						<div className={`menu-container ${menuScreenType}`}>
							<div className='menu-hide-container'>
								<div className={`menu-hide`}>
									<div className='menu-header'>
										<p>Profile Menu</p>
										<div className='hide-buttons'>
											{menuScreenType === 'tablet' && (
												<>
													<ButtonComponent
														onClick={toggleMenu}
														style='rounded-button tertiary'
														text=''
														icon={<i className='ri-arrow-left-line'></i>}
														iconOnly
													/>
													<ButtonComponent
														onClick={toggleMenu}
														style='rounded-button tertiary menu-button'
														text='Hide Menu'
													/>
												</>
											)}

											{menuScreenType === 'mobile' && (
												<ButtonComponent
													onClick={toggleMenu}
													style='rounded-button tertiary menu-button'
													text=''
													icon={<i className='ri-close-line'></i>}
													iconOnly
												/>
											)}
										</div>
									</div>
									<hr className='divider' />
									{menuOpen && (
										<MenuProfileComponent
											selectedItem={toggleState}
											onSelectedItem={toggleTab}
										></MenuProfileComponent>
									)}
								</div>
							</div>
						</div>
						<div className='overlay-close' onClick={toggleMenu}></div>
					</div>
				)}
				{menuScreenType === 'default' && (
					<div className={`menu-container ${menuScreenType}`}>
						<div className={`menu-hide`}>
							<ButtonComponent
								onClick={toggleMenu}
								style='rounded-button secondary menu-button'
								text='Hide Menu'
								icon={<i className='ri-menu-line'></i>}
								iconPosition='start'
							/>
						</div>
						<div className='menu-default'>
							<MenuProfileComponent
								selectedItem={toggleState}
								onSelectedItem={toggleTab}
							></MenuProfileComponent>
						</div>
					</div>
				)}
				<div className='main-content profile'>
					<div className='content'>
						<div className='title-content'>
							{toggleState === PROFILE_ROUTES.PROFILE && (
								<>
									<i className='ri-user-line' />
									<h2>Welcome, {state?.first_name}</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.INGREDIENTS && (
								<>
									<i className='ri-file-list-2-line' />
									<h2>Ingredient Templates</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.FAVORITES && (
								<>
									<i className='ri-heart-line' />
									<h2>Favorites</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.GET_ON_BEACON && (
								<>
									<i className='ri-information-line' />
									<h2>Getting Started on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.BRAND_INFO && (
								<>
									<i className='ri-edit-line' />
									<h2>Update Your Brand Info on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.PRODUCT_INFO && (
								<>
									<i className='ri-edit-line' />
									<h2>Update Your Product Info on Beacon Discovery</h2>
								</>
							)}
							{toggleState === PROFILE_ROUTES.ONBOARD_FAQ && (
								<>
									<i className='ri-question-mark' />
									<h2>Frequently Asked Questions</h2>
								</>
							)}
						</div>
						<div className='header-card'>
							<ButtonComponent
								onClick={toggleMenu}
								style='rounded-button secondary menu-button'
								text='Profile Menu'
								icon={<i className='ri-menu-line'></i>}
								iconPosition='start'
							/>
							{toggleState === PROFILE_ROUTES.PRODUCT_INFO && (
								<p className='count-products'>{productsState?.length} products</p>
							)}
						</div>
						{toggleState === PROFILE_ROUTES.PROFILE && tempData && (
							<div className='profile-card'>
								<h2 className='profile-title'>Profile</h2>

								<AccountFormComponent
									onUpdateProfile={updateProfile}
									userState={tempData}
									toggleEditing={toggleEditing}
									isEditing={activeEditing}
									onCancel={cancelUpdate}
								/>
								<CompanyFormComponent
									onUpdateProfile={updateProfile}
									userState={tempData}
									toggleEditing={toggleEditing}
									isEditing={activeEditing}
									onCancel={cancelUpdate}
								/>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.INGREDIENTS && (
							<div className='ingredient-card'>
								<NewProfileIngredientsComponent
									toggleState={toggleState}
									showDetails={showDetails}
									setShowDetails={setShowDetails}
									currentTabIndex={currentTabIndex}
									setCurrentTabIndex={setCurrentTabIndex}
									detailCurrentTab={detailCurrentTab}
									setDetailCurrentTab={setDetailCurrentTab}
									isEditing={ingredientsEditing}
									setEditing={setIngredientsEditing}
									moveToStepHandler={moveToStepHandler}
								/>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.FAVORITES && (
							<div ref={favoritesPageRef}>
								<div className='favorite-card'>
									<FavoritesPage isProfilePage={true} />
								</div>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.GET_ON_BEACON && (
							<div className='content-container'>
								{process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
									<div>
										<EngagementDashboardComponent
											engagementDashboardState={engagementDashboardState}
											brandId=''
											getEngagementData={getEngagementDashboardData}
											toggleState={toggleState}
										/>
									</div>
								)}
								<div>
									<BrandOnboard toggleState={toggleState} />
								</div>
							</div>
						)}
						{toggleState === PROFILE_ROUTES.BRAND_INFO && tempBrand && (
							<div className='brand-info-card'>
								<div className='title-container'>
									<StatusBubbleComponent
										status={brandState?.status as Status}
									></StatusBubbleComponent>
									<h3>{brandState?.name}</h3>
								</div>
								<div className='app-status'>
									<span>Application Status: </span>
									<span>{currentStatusBrand?.description}</span>
								</div>
								<div className='divider'></div>
								<div className='questions'>
									<i className='ri-question-mark' />
									<div className='info-questions-container'>
										<span>Questions? Reach out to </span>&nbsp;{' '}
										<span>
											<a href='mailto:beacondiscovery@newhope.com' target='_blank' rel='noreferrer'>
												{' '}
												beacondiscovery@newhope.com
											</a>
										</span>
									</div>
								</div>
								<StandardsApplicationComponent brandState={brandState} />
								<BrandFormComponent
									isEditing={activeEditing}
									onUpdateProfileBrand={updateBrand}
									onCancel={cancelUpdate}
									toggleEditing={toggleEditing}
									brandState={tempBrand}
								/>
								<ContactFormComponent
									isEditing={activeEditing}
									onUpdateProfileBrand={updateBrand}
									onCancel={cancelUpdate}
									toggleEditing={toggleEditing}
									brandState={tempBrand}
								/>
								<SocialMediaFormComponent
									isEditing={activeEditing}
									onUpdateProfileBrand={updateBrand}
									onCancel={cancelUpdate}
									toggleEditing={toggleEditing}
									brandState={tempBrand}
								/>
								<DistributionFormComponent
									isEditing={activeEditing}
									onUpdateProfileBrand={updateBrand}
									onCancel={cancelUpdate}
									toggleEditing={toggleEditing}
									brandState={tempBrand}
								/>
								{/* <FinancialFormComponent
									handleChange={() => {}}
									isEditing={activeEditing}
									onUpdateProfileBrand={() => {}}
									toggleEditing={toggleEditing}
									brandState={{}}
								/> */}
							</div>
						)}
						{toggleState === PROFILE_ROUTES.PRODUCT_INFO && (
							<>
								{openStates.length > 0 &&
									productsState &&
									productsState.map((item, index) => {
										return (
											<div className='product-info-card' key={index}>
												<div className='product-main-description-container'>
													<div className='product-description-container'>
														<div className='options-container'>
															<i
																className={
																	openStates[index].collapsed
																		? `ri-arrow-up-s-line`
																		: 'ri-arrow-down-s-line'
																}
																onClick={() => toggleOpen(index)}
															/>
															<span onClick={() => toggleOpen(index)}>
																{openStates[index].collapsed ? `Show Less` : 'Show More'}
															</span>
															<ButtonComponent
																onClick={() => {}}
																text='Live on Beacon'
																style={
																	filled ? 'rounded-button secondary' : 'rounded-button primary'
																}
															/>
														</div>

														<div className='product-description'>
															<h3>{item.name}</h3>
															<span>
																Some information will be seen by retailers when searching and
																filtering on Beacon Discovery. Please make sure that all information
																is accurate and up-to-date.
															</span>
															<div className='questions'>
																<i className='ri-question-mark' />
																<span>Questions? Reach out to </span>&nbsp;{' '}
																<span>
																	<a
																		href='mailto:beacondiscovery@newhope.com'
																		target='_blank'
																		rel='noreferrer'
																	>
																		{' '}
																		beacondiscovery@newhope.com
																	</a>
																</span>
															</div>
														</div>
													</div>
													<div className='product-image'>
														<div className='image-container'>
															<img alt='' src={item?.imageUrl} />
															<i className='ri-question-line' />
														</div>
													</div>
												</div>
												<div className='questions-tablet'>
													<i className='ri-question-mark' />
													<span>Questions? Reach out to </span>&nbsp;{' '}
													<span>
														<a
															href='mailto:beacondiscovery@newhope.com'
															target='_blank'
															rel='noreferrer'
														>
															{' '}
															beacondiscovery@newhope.com
														</a>
													</span>
												</div>
												<div className='product-main-description-container-mobile'>
													<div className='options-container'>
														<i
															className={
																openStates[index].collapsed
																	? `ri-arrow-up-s-line`
																	: 'ri-arrow-down-s-line'
															}
															onClick={() => toggleOpen(index)}
														/>
														<span onClick={() => toggleOpen(index)}>
															{openStates[index].collapsed ? 'Show Less' : 'Show More'}
														</span>
														<ButtonComponent
															onClick={() => {}}
															text='Live on Beacon'
															style={filled ? 'rounded-button secondary' : 'rounded-button primary'}
														/>
													</div>
													<h3>{item.name}</h3>
													<div className='product-image'>
														<div className='image-container'>
															<img alt='' src={item?.imageUrl} />
															<i className='ri-question-line' />
														</div>
													</div>
													<span>
														Some information will be seen by retailers when searching and filtering
														on Beacon Discovery. Please make sure that all information is accurate
														and up-to-date.
													</span>
													<div className='questions-mobile'>
														<i className='ri-question-mark' />

														<div className='info-questions-container'>
															<span>Questions? Reach out to </span>
															<span>
																<a
																	href='mailto:beacondiscovery@newhope.com'
																	target='_blank'
																	rel='noreferrer'
																>
																	{' '}
																	beacondiscovery@newhope.com
																</a>
															</span>
														</div>
													</div>
												</div>
												<div className=''>
													{' '}
													{openStates[index].collapsed && (
														<>
															<ProductAboutFormComponent
																productState={item}
															></ProductAboutFormComponent>
															<ProductDetailsFormComponent
																isEditing={activeEditing}
																onUpdateProfileProduct={updateProduct}
																onCancel={cancelUpdate}
																productState={item}
																toggleEditing={toggleEditing}
															/>
															<ProductCertificationsFormComponent productState={item} />
														</>
													)}
													<ProductSustainabilityFormComponent
														isEditing={activeEditing}
														onUpdateProfileProduct={updateProduct}
														onCancel={cancelUpdate}
														productState={item}
														toggleEditing={toggleEditing}
														showHowGoodCard={openStates[index].collapsed}
													/>
													<ProductStandardsFormComponent
														productState={item}
													></ProductStandardsFormComponent>
													<ProductViolationsFormComponent productState={item} />
												</div>
												<ButtonComponent
													text={
														openStates[index].collapsed
															? 'Hide Product Information'
															: 'Show More Product Information'
													}
													onClick={() => toggleOpen(index)}
													iconPosition='start'
													icon={
														<i
															className={`${
																openStates[index].collapsed
																	? `ri-arrow-up-s-line`
																	: 'ri-arrow-down-s-line'
															}`}
														/>
													}
													style={`button-show`}
												></ButtonComponent>
											</div>
										);
									})}
							</>
						)}
						{toggleState === PROFILE_ROUTES.ONBOARD_FAQ && (
							<>
								<FAQComponent />
							</>
						)}
					</div>
				</div>
			</div>
			<div
				className={`tabs-container ${
					toggleState === '2' || toggleState === '4' ? 'ingredient-background' : ''
				}`}
			>
				{/* {process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
						<>
							{(state?.user_type === JobTypeKeys.Exhibitor ||
								state?.user_type === JobTypeKeys.BeaconOnlyBrand) && (
								<li
									data-testid='charts-tab'
									className={`tabs ${getActiveClass(4, 'active-tabs')}`}
									onClick={() => toggleTab(4)}>
									<i id='profile-chart-icon' className='ri-pie-chart-2-fill'></i>{' '}
								</li>
							)}
						</>
					)} */}
			</div>
			<div>
				{toggleState === PROFILE_ROUTES.INGREDIENTS && templates.length > 0 && (
					<div id='target'>
						<NewDetailedTemplateComparisonComponent
							currentTab={ingredientsEditing ? currentTabIndex : detailCurrentTab}
							templates={templates}
							moveToTab={moveToStepHandler}
							setShowDetails={setShowDetails}
							showDetails={showDetails}
							isMobile={menuScreenType !== 'default'}
							isEdit={ingredientsEditing}
							previewTab={previewTab}
						/>
					</div>
				)}
			</div>
			<div className='wave'></div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	engagementDashboardState: state?.dashboardState,
	ingredientTemplates: state.storeState?.templates,
	ingredientsTemplateId: state.userState?.ingredientsTemplateId
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setProfileLayout: (isProfileLayout: boolean) => {
		dispatch(AppActions.setProfileLayout(isProfileLayout));
	},
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => {
		dispatch(AppActions.isOnFavoritesPage(isOnFavoriteTab));
	},
	getEngagementDashboardData: (brandId: string) => {
		dispatch(EngagementDashboardActions.startFetchingEngagementData(brandId));
	},
	setIngredientsTemplateId: (ingredientsTemplateId: number) =>
		dispatch(UserActions.setIngredientsTemplateId(ingredientsTemplateId)),
	getTemplates: () => {
		return dispatch(ApolloActions.query({ query: GET_INGREDIENT_TEMPLATES }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserProfile);
