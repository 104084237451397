import { gql } from 'graphql.macro';

export const GET_ONBOARDING_BRAND = gql`
	query GetOnboardingBrand($email: String!) {
		getBrandFromAdmin(email: $email) {
			name
			status
			brandLogo
			accountPrimaryContactName
			accountPrimaryContactEmail
			brandRepEmail
			brandRepName
			brandRepPhone
			brandRepTitle
			brandStory
			brandMission
			brandInstagram
			brandYoutube
			brandTwitter
			brandLinkedin
			brandFacebook
			brandTiktok
			brandVideo
			brandWebsite
			brandYearFound
			holdingCompany
			brandHQCountry
			brandHQState
			brandHQCity
			productRepEmail
			productRepName
			productRepPhone
			productRepTitle
			mapPolicy
			otherServicesNeeds {
				contractManufacturingPrivateLabel
				fulfillmentLogistics
				packagingPlastics
				sustainablePackaging
				labelPrinting
				qualityAssurance
				certifyingAgentOrganic
				formulationClinicalResearch
				mediaMarketingAgency
				manufacturingProcessingEquipment
				legalRegulatoryConsulting
				contactlessSampling
				staffingServices
				financeAccountingPayrollServices
				financialInstitutionInvestmentBanking
				investors
				onlineBusinessSolutions
				artificialIntelligence
				artificialIntelligence
				seekDistribution
				seekBrokerWholesaler
				otherNotListedHere
				noneAbove
			}
			currentRetailChannels {
				conventionalRetailers
				naturalChain
				independent
				directConsumers
				eTailer
			}
			ownedByParentCompany
			ownedByHoldingCompany
			parentCompany
			privateLabeServices
			brokers {
				name
				phone
				email
			}
			processingTechniques {
				id
				name
			}
			processingTechniques {
				id
				name
			}
			currentDistributionChannels {
				KeHe
				UNFI
				Other
				NotInDistribution
			}
			keheRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
			UNFIRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
			otherDistributorRegion {
				northEast
				east
				southEast
				central
				west
				none
			}
		}
	}
`;
