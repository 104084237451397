import React from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './ContactFormComponent.scss';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { getIn } from 'formik';
import { validationMessages } from '../../../../common/constants';
import { validateExactLengthMessage } from '../../../../helpers/formFieldValidations';
interface IContactForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}

const BrandContactFormSchema = Yup.object().shape({
	brandRepName: Yup.string()
		.required(validationMessages.field_required)
		.max(150, validationMessages.max_value),
	brandRepEmail: Yup.string()
		.email(validationMessages.invalid_email)
		.required(validationMessages.field_required)
		.max(250, validationMessages.max_value),
	brandRepPhone: Yup.string().test(
		'length',
		validateExactLengthMessage('10 digits'),
		val => val !== undefined && val?.toString().length === 10
	),
	brandRepTitle: Yup.string().required().max(70, validationMessages.max_value),
	haveABroker: Yup.boolean(),
	brokers: Yup.array().of(
		Yup.object().shape({
			name: Yup.string()
				.required(validationMessages.field_required)
				.max(150, validationMessages.max_value),
			phone: Yup.string()
				.test(
					'length',
					validateExactLengthMessage('10 digits'),
					val => val !== undefined && val?.toString().length === 10
				)
				.required(),
			email: Yup.string()
				.email(validationMessages.invalid_email)
				.required()
				.max(250, validationMessages.max_value)
		})
	)
});

const ContactFormComponent: React.FC<IContactForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'contact-form';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);

	const { errors, values, handleChange, handleReset, submitForm, isValid } = useFormik({
		initialValues: {
			contact_name: brandState.brandRepName,
			contact_email: brandState.brandRepEmail,
			contact_phone: brandState.brandRepPhone,
			contact_title: brandState.brandRepTitle,
			haveABroker: brandState.brokers?.length! > 0 ? true : false,
			brokers: brandState.brokers
		},
		onSubmit: values => {
			console.log(values);
			onUpdateProfileBrand('');
		},
		validationSchema: BrandContactFormSchema
	});

	return (
		<div className='contact-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => onUpdateProfileBrand(state)}
				titleText='Contact Information for Retailers'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='Brand Rep Name'></LabelForms>

										<p>{state?.brandRepName}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Email'></LabelForms>
										<p>{state?.brandRepEmail}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Phone Number'></LabelForms>
										<p>{state?.brandRepPhone}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Job Title'></LabelForms>
										<p>{state?.brandRepTitle}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Name'></LabelForms>
										<p>{state?.productRepName}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Email'></LabelForms>
										<p>{state?.productRepEmail}</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Do you Have a Broker?'></LabelForms>

										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									{/* <div>
										<LabelForms text='Broker 1 Details'></LabelForms>

										<p>{state?.name}</p>
									</div>
									<div>
										<LabelForms text='Broker 2 Details'></LabelForms>
										<p className={state?.boothNumber ? '' : 'blank-field'}>{state?.boothNumber}</p>
									</div>
									<div>
										<LabelForms text='Broker 3 Details'></LabelForms>
										<p className={state?.boothNumber ? '' : 'blank-field'}>{state?.boothNumber}</p>
									</div> */}
									<div>
										<LabelForms text='Product Rep Phone Number'></LabelForms>
										<p>{state?.productRepPhone}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Job Title'></LabelForms>
										<p>{state?.productRepTitle}</p>
									</div>
								</>
							}></ContainerForms>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='Brand Contact Name'></LabelForms>
										<TextInputComponent
											error={errors.contact_name?.length! > 0}
											errorHelperText={<div>{errors.contact_name}!</div>}
											placeholder='Brand Contact Name'
											name='contact_name'
											onChange={handleChange}
											value={values.contact_name!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Contact Email'></LabelForms>
										<TextInputComponent
											error={errors.contact_email?.length! > 0}
											errorHelperText={<div>{errors.contact_email}!</div>}
											placeholder='Brand Contact Email'
											name='contact_email'
											onChange={handleChange}
											value={values.contact_email!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Contact Phone'></LabelForms>
										<TextInputComponent
											error={errors.contact_phone?.length! > 0}
											errorHelperText={<div>{errors.contact_phone}!</div>}
											placeholder='Brand Contact Phone'
											name='contact_phone'
											onChange={handleChange}
											value={values.contact_phone!}></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Brand Contact Title'></LabelForms>
										<TextInputComponent
											error={errors.contact_title?.length! > 0}
											errorHelperText={<div>{errors.contact_title}!</div>}
											placeholder='Brand Contact Title'
											name='contact_title'
											onChange={handleChange}
											value={values.contact_title!}></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Do you Have a Broker?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='Do_you_have_a_broker'
											value={values.haveABroker}
											disabled={false}
											onChange={handleChange}></CheckboxFormComponent>
									</div>
									{values.brokers!.map((element, index) => (
										<div key={index}>
											<div>
												<LabelForms text={`Broker ${index + 1} Name`}></LabelForms>
												<TextInputComponent
													error={getIn(errors, `brokers[${index}].name`) !== undefined}
													errorHelperText={<div>{getIn(errors, `brokers[${index}].name`)}!</div>}
													placeholder='Broker Name'
													name={`brokers.${index}.name`}
													onChange={handleChange}
													value={element.name}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={`Broker ${index + 1} Phone`}></LabelForms>
												<TextInputComponent
													error={getIn(errors, `brokers[${index}].phone`) !== undefined}
													errorHelperText={<div>{getIn(errors, `brokers[${index}].phone`)}!</div>}
													placeholder='Broker Phone'
													name={`brokers.${index}.phone`}
													onChange={handleChange}
													value={element.phone}></TextInputComponent>
											</div>
											<div>
												<LabelForms text={`Broker ${index + 1} Email`}></LabelForms>
												<TextInputComponent
													error={getIn(errors, `brokers[${index}].email`) !== undefined}
													errorHelperText={<div>{getIn(errors, `brokers[${index}].email`)}!</div>}
													placeholder='Broker Email'
													name={`brokers.${index}.email`}
													onChange={handleChange}
													value={element.email}></TextInputComponent>
											</div>
										</div>
									))}
								</>
							}></ContainerForms>
						<>
							<ActionsForms
								onUpdate={() => submitForm()}
								onCancel={() => {
									onCancel();
									handleReset(null);
								}}
								isValid={isValid}
								toggleEditing={() => toggleEditing(null)}></ActionsForms>
						</>
					</>
				))}
		</div>
	);
};

export default React.memo(ContactFormComponent);
