import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import { Product } from '../../../../../../../common/model';
import React from 'react';
import './ProductViolationsFormComponent.scss';
import { useWindowSize } from '../../../../hooks/useWindowSize';

interface IProductViolationsForm {
	productState: any;
}
interface IWarnings {
	id: string;
	component: JSX.Element;
}

const ProductViolationsFormComponent: React.FC<IProductViolationsForm> = ({ productState }) => {
	const [state, setState] = useState<Product>();
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	const { width } = useWindowSize();
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);

	return (
		<div className='product-violations-form'>
			<TitleForms
				isVisible={isVisible}
				titleText='Violations'
				toggleVisibility={toggleVisibility}
				readOnly
				warning></TitleForms>
			{isVisible && (
				<>
					<p className='helper-text'>
						To provide additional information, please contact your Standards Specialist at
						<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
							{' '}
							standards@newhope.com.
						</a>{' '}
					</p>
					<ContainerForms
						FormComponent1={
							<>
								{' '}
								<div>
									<LabelForms
										text='Allergen Warning Missing
					'
										toolTipText={
											<>
												Product contains an ingredient that requires an allergen declaration which
												is not present. Allergen declaration is required.
											</>
										}
										icon={<i className='ri-question-line' />}
										toolTipPosition='right'
										isMobile={isMobile}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Claims or TM logo requires certification'
										toolTipText={
											<>
												Product label indicates the product is certified organic, certified gluten
												free and/or non-GMO verified/certified. The supporting certificate
												documentation is required or the label may require appropriate revisions.
											</>
										}
										icon={<i className='ri-question-line' />}
										toolTipPosition='right'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Botanic plant parts missing
											'
										toolTipText={
											<>
												Dietary supplement products containing botanical substances are required to
												include the plant parts used within the ingredient declaration.
											</>
										}
										icon={<i className='ri-question-line' />}
										toolTipPosition='right'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Directions for use'
										toolTipText={
											<>
												Products that could be unsafe if used improperly must identify directions
												for safe use and warnings against misuse. Appropriate directions for use are
												missing from the label.
											</>
										}
										icon={<i className='ri-question-line' />}
										toolTipPosition='right'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms text='Facts panel missing or incorrect'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Ingredients list missing or incorrect'
										toolTipText={
											<>
												Ingredients list includes items that must be broken down into its
												sub-ingredient components; ingredients declaration is incorrect. OR product
												contains more than one ingredient making an ingredients list required, and
												it is missing.
											</>
										}
										toolTipPosition='right'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Manufacturer address missing or inadequate'
										toolTipText={
											<>
												For the address to be complete, a city, state and zip code are required. If
												any, or all of these components are missing and/or only a web address is
												present, the required address is missing or incomplete.
											</>
										}
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Misuse of an FDA approved health claim'
										toolTipText={
											<>
												The product may meet the requirements to use an U.S. FDA approved health
												claim, but the language of the claim does not align with the language
												approved for use by the U.S. FDA.
											</>
										}
										toolTipPosition='right'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Net quality of contents missing or inadequate'
										toolTipText={
											<>
												Net quanity of contents must be present in both avoirdupois measure (g.,
												ounce, pound, fluid ounce, pint, quart) and metric measure (e.g., milligram,
												gram, milliliter, liter). One of these measurements is missing on the label.
											</>
										}
										toolTipPosition='right'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
							</>
						}
						FormComponent2={
							<>
								{' '}
								<div>
									<LabelForms text='Natural claims'></LabelForms>
									<p className='info-text'>False</p>
								</div>{' '}
								<div>
									<LabelForms
										text='Organic certifying agent missing'
										toolTipText={
											<>
												Product label includes organic claims that require the product be certified
												organic with the certifying agent identified on the information panel. The
												certyfiing agent does not appear on this product label and it is required.
												If the product is not certified organic, the organic claim(s) need to be
												removed.
											</>
										}
										toolTipPosition='left'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms text='Statement of identity missing'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Unclarified nutrient amount on PDPF'
										toolTipText={
											<>
												The nutrient amount declared on the PDP does not match the amount per
												serving in the Facts panel. It is unclear if this amount is per container or
												per serving or some other number of servings. Clarification on the label is
												required.
											</>
										}
										toolTipPosition='left'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Disease claims'
										toolTipText={
											<>
												Product includes a claim that establishes the intended use of the product as
												a drug. It has also been determined that the product does not qualify for an
												FDA approved health claim. New Hope Network Standards do not permit products
												to make disease or drug-like clams.
											</>
										}
										toolTipPosition='left'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='FDA logo misuse'
										toolTipText={
											<>
												Product includes the FDA logo that is not for use on branded products per
												FDA logo policy. Please see details here:{' '}
												<a
													href='https://www.fda.gov/about-fda/website-policies/fda-name-and-logo-policy'
													target='_blank'>
													https://www.fda.gov/about-fda/website-policies/fda-name-and-logo-policy
												</a>
											</>
										}
										toolTipPosition='left'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms text='Nutrient Content Claims'></LabelForms>
									<p className='info-text'>False</p>
								</div>
								<div>
									<LabelForms
										text='Banned ingredient list '
										toolTipText={<>Product contains these ingredients which are prohibited.</>}
										toolTipPosition='left'
										icon={<i className='ri-question-line' />}></LabelForms>
									<p className='info-text'>False</p>
								</div>
							</>
						}></ContainerForms>
				</>
			)}
		</div>
	);
};

export default React.memo(ProductViolationsFormComponent);
